<template>
  <div class="home">
    <!-- <el-upload
      class="avatar-uploader"
      action="#"
      :show-file-list="false"
      accept="*"
      :on-change="handleAvatarChange"
      :auto-upload="false"
    >
      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload> -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    handleAvatarChange(file) {
      console.log(file);
      this.getTimes(file);
    },
    getTimes(file) {
      var content = file.raw;
      //获取录音时长
      var url = URL.createObjectURL(content);
      //经测试，发现audio也可获取视频的时长
      var audioElement = new Audio(url);
      audioElement.addEventListener("loadedmetadata", () => {
        const audioDuration = audioElement.duration;
        console.log(audioDuration);
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
